import React, { useState } from 'react';
import Alert from '../components/Alert';

export default function Upload() {
	const [fileInputState, setFileInputState] = useState('');
	const [textInputState, setTextInputState] = useState('');
	const [previewSource, setPreviewSource] = useState('');
	const [selectedFile, setSelectedFile] = useState();
	const [successMsg, setSuccessMsg] = useState('');
	const [errMsg, setErrMsg] = useState('');
	const [show, setShow] = useState(true);

	const handleFileInputChange = (e) => {
		console.log(e.target.type)
	 if(e.target.type === "text"){
		 setTextInputState(e.target.value)
		 return 
	 }

		const file = e.target.files[0];
		previewFile(file);
		setSelectedFile(file);
		setFileInputState(e.target.value);
	};

	const previewFile = (file) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		console.log(file.name)
		reader.onloadend = () => {
			setPreviewSource(file.name);
		};
	};

	const handleSubmitFile = async (e) => {
		e.preventDefault();
		if (!selectedFile) return;

		setShow(false)
		console.log( textInputState )
		console.log( fileInputState );
		const formData = new FormData();

		formData.append("fileUpload", selectedFile)
		formData.append("chave", textInputState)

		try {
			const response = await fetch('https://apiv20.diallink.com.br/upload1', {
				method: 'POST',
				body: formData,
			})
			console.log(response.status)
			if (response.status !== 200){

				setFileInputState('');
				setErrMsg('Algo deu errado, tente novamente!');
				setPreviewSource("Erro ao carregar o arquivo");
			}else{

				setFileInputState('');
				setPreviewSource('Arquivo carregado com sucesso');
				setSuccessMsg('Arquivo carregado com sucesso!');
			}

			setShow(true)
		} catch (err) {
			console.error(err);
			setErrMsg('Algo deu errado, tente novamente!');
			setShow(true)
		}
	};

	return (
		<div>
			<h1 className="title">Upload de arquivo Sorteio </h1>
			<Alert msg={errMsg} type="danger" />
			<Alert msg={successMsg} type="success" />
		<form onSubmit={handleSubmitFile} className="form">
		<div>
			<label>
				Chave de acesso:
			</label>
			<input required type="text" name="chave" onChange={handleFileInputChange} value={textInputState} />
		</div> <br/> <br/>
		<input required
			id="fileInput"
			type="file"
			name="image"
			onChange={handleFileInputChange}
			value={fileInputState}
			className="form-input"
		/>
		{
			show ? <button className="btn" type="submit"> Enviar </button>: <p>Processando...</p>
		}
		</form>
		{previewSource && (
			<h1>
			{previewSource}
			</h1>
		)}
		</div>
	);
}
